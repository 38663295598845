import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import LogoCycle from '../../_assets/images/logos/logo-cycle-rose.svg';
import { Color } from '../../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../../components/common/page-layout/page-layout';
import FaqEntries from '../../components/templates/faq-entries/faq-entries';
import HeadlineCopytext from '../../components/templates/headline-copytext/headline-copytext';
import ResponsiveImage from '../../components/templates/responsive-image/responsive-image';
import SectionIntended from '../../components/templates/section-intended/section-intended';

import FaqStyles from './_scss/faq.module.scss';

const FaqCyclePage = () => {
    const intl = useIntl();

    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.faqCycle.title' })}
            pageBackground={PageLayoutBackground.Black}
            topPadding={false}
        >
            <div className={FaqStyles.faqHead}>
                <ResponsiveImage
                    images={[
                        {
                            path: '_assets/pages/service/faq-cycle/header.jpg',
                            media: '',
                        },
                    ]}
                    altText=""
                />

                <div className={FaqStyles.faqHeadContent}>
                    <HeadlineCopytext
                        alignment="center"
                        color={Color.White}
                        headline={[
                            { text: intl.formatMessage({ id: 'pages.faqCycle.headline.0' }), style: 'h1' },
                            { text: intl.formatMessage({ id: 'pages.faqCycle.headline.1' }), style: 'h2' },
                        ]}
                    />
                </div>
            </div>
            <SectionIntended noPadding={true}>
                <div className={FaqStyles.faqSubhead}>
                    <img src={LogoCycle} height="26" alt="@Cycle" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqCycle.firstSteps.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqCycle.firstSteps.questions" activeEntry={0} />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqCycle.training.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqCycle.training.questions" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqCycle.handling.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqCycle.handling.questions" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqCycle.troubleshooting.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqCycle.troubleshooting.questions" />
                </div>

                <div className={FaqStyles.faqSection}>
                    <h3 className="color-white">
                        <FormattedMessage id="pages.faqCycle.subscription.headline" />
                    </h3>
                    <FaqEntries messageNodeId="pages.faqCycle.subscription.questions" />
                </div>
            </SectionIntended>
        </PageLayout>
    );
};

export default FaqCyclePage;
